<template>
  <div id="automation">
    <b-row
      v-if="!specialCategory(response.type) && response.type != 'LIN'"
      class="match-height mt-2"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showKeep"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header style="display: block;">
              <b-card-title>
                {{ $t('automationChap') }}
              </b-card-title>
              <h6 class="text-primary ">
                {{ $t('establised') }}
              </h6>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    @click="keepWatching()"
                  >
                    {{ $t('rel') }}
                  </b-button>
                </b-col>
                <b-col
                  v-if="buscadoKeep"
                  style="text-align:end"
                  class="mr-2"
                >
                  <b-badge
                    variant="primary"
                    style="font-size: 14px;"
                    class="m-2"
                  >
                    {{ dataChapters.length + ' ' + $t('contents.elements') }}
                  </b-badge>
                  <button
                    class="btn btn-success "
                    @click="asgKeepWatching()"
                  >
                    {{ $t('aplicar') }}
                  </button>
                </b-col>
              </b-row>
              <b-row
                v-if="buscadoKeep"
                class="d-flex mr-1"
              >
                <b-col class="mb-2">
                  <div />
                </b-col>
              </b-row>
              <b-row
                v-if="buscadoKeep"
                class="d-flex mr-1"
              >
                <b-col class="ml-2 mb-2">
                  <b-table
                    id="chapters"
                    striped
                    responsive
                    :sticky-header="true"
                    :no-border-collapse="true"
                    :items="chapters"
                    :fields="columns"
                    class="mb-0"
                  >
                    <template #cell(prev)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.prev ? data.item.prev.imageUrl : null)"
                            style=" margin-right: 2rem;
                          width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b
                            v-if="data.item.prev"
                            class="m-2"
                          >
                            <b-link
                              class="cursor-pointer"
                              :href="'/contents/contents/edit/' + data.item.prev.id"
                              target="_blank"
                            >
                              {{ data.item.prev.name }}
                            </b-link>
                          </b>
                          <span
                            v-else
                            class="text-muted"
                          >{{ $t('noChapter') }}</span>
                        </b-col>
                      </b-row>
                    </template>
                    <template #cell(Contenido)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.imageUrl)"
                            style=" margin-right: 2rem;
                            width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b class="m-2">
                            <b-link
                              class="cursor-pointer"
                              :href="'/contents/contents/edit/' + data.item.id"
                              target="_blank"
                            >
                              {{ data.item.name }}
                            </b-link>
                          </b>
                        </b-col>
                      </b-row>
                    </template>

                    <template #cell(next)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.next ? data.item.next.imageUrl : null)"
                            style=" margin-right: 2rem;
                            width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b
                            v-if="data.item.next"
                            class="m-2"
                          >
                            <b-link
                              class="cursor-pointer"
                              :href="'/contents/contents/edit/' + data.item.next.id"
                              target="_blank"
                            >
                              {{ data.item.next.name }}
                            </b-link>
                          </b>
                          <span
                            v-else
                            class="text-muted"
                          >{{ $t('noChapter') }}</span>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header style="display: block;">
              <b-card-title>
                {{ $t('automData') }}
              </b-card-title>
              <h6
                v-if="!final"
                class="text-primary "
              >
                {{ $t('automDataCat') }}
              </h6>
              <h6
                v-else
                class="text-primary "
              >
                {{ $t('autCont') }}
              </h6>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="mb-2">
                  <b-button
                    class="mt-2 mr-2"
                    variant="primary"
                    @click="clickElement()"
                  >
                    {{ $t('analizar') }}
                  </b-button>

                  <b-button
                    v-if="!final"
                    class="mt-2"
                    variant="primary"
                    @click="clickCategory()"
                  >
                    {{ $t('subcat') }}
                  </b-button>
                </b-col>
                <b-col style="text-align: right;">
                  <b-badge
                    v-if="buscado"
                    variant="primary"
                    style="font-size: 14px;"
                    class="m-2"
                  >
                    {{ contents.length + external.length + ' ' + $t('contents.elements') }}
                  </b-badge>
                  <b-badge
                    v-if="buscadoCat"
                    variant="primary"
                    style="font-size: 14px;"
                    class="m-2"
                  >
                    {{ categories.length + ' ' + $t('contents.elements') }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                v-if="response != null ? response.mediaLocation != 'BUK' : false
                "
              >
                <b-col>
                  <b-alert
                    show
                    variant="warning"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="AlertTriangleIcon"
                      />
                      <span>
                        {{ $t('originMedia') }}
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row v-if="buscado">
                <b-col>
                  <h4 class="ml-2">
                    {{ $t('mas') }}
                  </h4>

                  <b-list-group class="scroll-list  m-1">
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t("contents.bckgInternal") }}: </b>

                        <b-img
                          :src="buildImageUrl(backgroundUrl)"
                          style=" margin-right: 2rem;
                            width: 6rem;"
                          @error="errorImg"
                        />
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="background != null && background != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgImg()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.ageClasific') }}: </b>
                        <h5>
                          {{
                            clasification != null
                              ? clasification.initial +
                                "-" +
                                clasification.description
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="clasification != null ? false : true"
                          variant="success"
                          @click="asgClas()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.genre') }}: </b>
                        <h5>
                          {{ genre != null ? genre.name : $t('emptyField') }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="genre != null ? false : true"
                          variant="success"
                          @click="asgGen()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('code.type') }} : </b>
                        <h5>
                          {{
                            type != null ? $t(categoryTypes[type]) : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="type != null ? false : true"
                          variant="success"
                          @click="asgType()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('resByUser') }}: </b>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('limitNmax')"
                        >
                          <b-form-input
                            v-model="maxViews"
                            type="number"
                            maxlength="30"
                          />
                        </b-form-group>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgMaxViews()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('category.shortDescription') }}: </b>
                        <h5>
                          {{
                            shortDescription != "" && shortDescription != null
                              ? shortDescription
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="shortDescription != null && shortDescription != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgShortDes()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row
                          class="d-flex align-items-center"
                          style="margin-right: auto;"
                        >
                          <b-col>
                            <b>{{ $t('statusCont') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label=""
                              class="mb-2 mr-1"
                            >
                              <b-form-select
                                id="labelStatus"
                                v-model="labelStatus"
                                :label="$t('message.tableHeader.status')"
                                @change="estados()"
                              >
                                <b-form-select-option
                                  v-for="s in status"
                                  :key="s.value"
                                  :value="s.value"
                                >
                                  {{ s.text }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row
                          id="fechas"
                          style="display: none;"
                        >
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.datePublication')"
                            >
                              <flat-pickr
                                v-model="publishingDate"
                                class="form-control"
                                :placeholder="$t('contents.datePublication')"
                                :config="{
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'Y-m-d H:i:S',
                                  minDate: 'today'
                                }"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.dateFinishing')"
                            >
                              <flat-pickr
                                v-model="finishingDate"
                                class="form-control"
                                :placeholder="$t('contents.dateFinishing')"
                                :config="{
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'Y-m-d H:i:S',
                                  minDate: publishingDate
                                }"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="labelStatus != null ? false : true"
                          variant="success"
                          @click="asgStatus()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group class="mb-2 mr-1">
                            <label class="d-flex">
                              {{ $t('tagsCont') }}
                              <feather-icon
                                v-b-tooltip.hover.top.v-primary="$t('addTags')
                                "
                                class="ml-1"
                                icon="InfoIcon"
                              />
                            </label>
                            <v-select
                              v-model="selectedTag"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              multiple
                              taggable
                              push-tags
                              :placeholder="$t('addTagsC')"
                              @input="modalTags()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="contents.length + external.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedTag == 0"
                            @click="asgTags()"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="showDownload"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contDown') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isDownload"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgDownload()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('showContentsCategories') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="showContentsCategories"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgCheckContents()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="platformMode == 'FRM'"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('freemium') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isFreemium"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgFreemium()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="showAds"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contAd') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isAds"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgAds()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <!-- hasSubtitle -->
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contSub') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="hasSubtitle"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSub()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <!-- endsubtitle -->
                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group class="mb-2 mr-1">
                            <label class="d-flex">
                              {{ $t('zones.title') }}
                            </label>
                            <v-select
                              v-model="selectedZones"
                              clearable
                              multiple
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('addZones')"
                              :options="initialZones"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="contents.length + external.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedZones.length === 0"
                            @click="asgZones()"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('firstCat') }}: </b>
                        <h5>
                          {{ maincategory }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgMainCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('season') }}: </b>
                        <h5>
                          {{ seasonCategory }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSeasonCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('qualityCont') }}: </b>
                        <h5>
                          {{ qualityOptions[quality] }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgQuality()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                      class="d-flex justify-content-between "
                    >
                      <b-row class="align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('Subgroup') }}:</b>
                        </b-col>
                        <b-col
                          v-if="subgroups"
                          class="subgroup"
                        >
                          <b-form-group>
                            <v-select
                              id="subgroup"
                              v-model="subgroup"
                              :options="subgroups"
                              label="name"
                              :placeholder="$t('SinGrupoUsuario')"
                              aria-autocomplete="new-password"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSubgroup()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="true"
                      class="d-flex justify-content-between "
                    >
                      <b-row class="align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('interactivities.automation') }}:</b>
                        </b-col>
                      </b-row>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click.stop.prevent="showModal()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
              <b-row v-if="buscadoCat">
                <b-col>
                  <h4 class="ml-2">
                    {{ $t('masiveChange') }}
                  </h4>
                  <b-list-group class="scroll-list m-1">
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t("contents.bckgInternal") }}: </b>

                        <b-img
                          :src="buildImageUrl(backgroundUrl)"
                          style=" margin-right: 2rem;
                            width: 6rem;"
                          @error="errorImg"
                        />
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="background != null && background != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgImg(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.ageClasific') }}: </b>
                        <h5>
                          {{
                            clasification != null
                              ? clasification.initial +
                                "-" +
                                clasification.description
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="clasification != null ? false : true"
                          variant="success"
                          @click="asgClas(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.genre') }}: </b>
                        <h5>
                          {{ genre != null ? genre.name : $t('emptyField') }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="genre != null ? false : true"
                          variant="success"
                          @click="asgGen(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('category.shortDescription') }}: </b>
                        <h5>
                          {{
                            shortDescription != "" && shortDescription != null
                              ? shortDescription
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="shortDescription != null && shortDescription != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgShortDes(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <b-row class="d-flex align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('statusSub') }}</b>
                        </b-col>
                        <b-col>
                          <b-form-group label="">
                            <b-form-select
                              id="isActive"
                              v-model="isActive"
                              :label="$t('message.tableHeader.status')"
                            >
                              <b-form-select-option
                                v-for="s in status"
                                :key="s.value"
                                :value="s.value"
                              >
                                {{ s.text }}
                              </b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div
                        v-if="categories.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgActive()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between ">
                      <b-row class="d-flex align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('qualityCat') }}: </b>
                        </b-col>
                        <b-col>
                          <h5>
                            {{ qualityOptions[quality] }}
                          </h5>
                        </b-col>
                      </b-row>
                      <div
                        v-if="categories.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgQualityCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group class="mb-2 mr-1">
                            <label class="d-flex">
                              {{ $t('tagsCat') }}
                              <feather-icon
                                v-b-tooltip.hover.top.v-primary="$t('addTags')
                                "
                                class="ml-1"
                                icon="InfoIcon"
                              />
                            </label>
                            <v-select
                              v-model="selectedTag"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              multiple
                              taggable
                              push-tags
                              :placeholder="$t('addTagsC')"
                              @input="modalTags()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="categories.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedTag == 0"
                            @click="asgTags(true)"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contSub') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="hasSubtitle"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSub(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group class="mb-2 mr-1">
                            <label class="d-flex">
                              {{ $t('zones.title') }}
                            </label>
                            <v-select
                              v-model="selectedZones"
                              clearable
                              multiple
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('addZones')"
                              :options="initialZones"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="categories.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedZones.length === 0"
                            @click="asgZones(true)"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                      class="d-flex justify-content-between "
                    >
                      <b-row class="align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('Subgroup') }}:</b>
                        </b-col>
                        <b-col
                          v-if="subgroups"
                          class="subgroup"
                        >
                          <b-form-group>
                            <v-select
                              id="subgroup"
                              v-model="subgroup"
                              :options="subgroups"
                              label="name"
                              :placeholder="$t('SinGrupoUsuario')"
                              aria-autocomplete="new-password"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div
                        v-if="categories.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSubgroup(true)"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>

        <b-card v-if="external != null ? external.length != 0 : false">
          <b-card-header>
            <b-card-title>
              {{ $t('problem') }}
              {{ dataExternal.length }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="d-flex  mr-1">
              <b-col class="ml-2 mb-2">
                <b-table
                  id="dataExternal"
                  striped
                  responsive
                  :sticky-header="true"
                  :no-border-collapse="true"
                  :items="dataExternal"
                  :fields="tableColumns"
                  class="mb-0"
                >
                  <template #cell(Contenido)="data">
                    <div class="d-flex align-items-center">
                      <b-img
                        :src="buildImageUrl(data.item.imageUrl)"
                        style=" margin-right: 2rem;
                            width: 6rem;"
                        @error="errorImg"
                      />
                      <b class="m-2">{{ data.item.name }}</b>
                    </div>
                  </template>
                  <template #cell(Editar)="data">
                    <div>
                      <router-link
                        :to="{
                          name: 'contents-edit',
                          params: { id: data.item.id }
                        }"
                      >
                        <b-button variant="info">
                          {{ $t('dataGeneric.edit') }}
                        </b-button>
                      </router-link>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="IntModal"
      size="xl"
      hide-footer
      :title="$t('select')"
    >
      <div class="d-block text-center">
        <interactivity-selector @confirm-selection="InteractivitySelection" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>

import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BFormSelectOption,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BListGroup, VBTooltip,
  BBadge,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BLink,
  BImg,
  BTable,
  BAlert,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  showToast, messageError, specialCategory, isUserInGroup, checkPermissions, getSubgroups,
  b64ToUtf8,
} from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import configCategoryType from '@core/utils/categoryType'
import * as constants from '@core/utils/constants'
import { getQualityOptions } from '@/@core/utils/utils'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import InteractivitySelector from '@/views/common/InteractivitySelector.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BBadge,
    BLink,
    BFormCheckbox,
    BFormInput,
    flatPickr,
    BButton,
    BRow,
    BCol,
    BListGroupItem,
    BAlert,
    BCard,
    BFormSelectOption,
    BCardHeader,
    vSelect,
    BListGroup,
    BCardTitle,
    BCardBody,
    BTable,
    BImg,
    BOverlay,
    InteractivitySelector,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    response: {
      type: Object,
      default() {
        return {}
      },
    },
    final: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      specialCategory,
      show: false,
      isDownload: false,
      showContentsCategories: false,
      hasSubtitle: false,
      headers: {},
      isAds: false,
      showKeep: false,
      contents: [],
      contentData: [],
      buscado: false,
      showAds: false,
      showDownload: false,
      maincategory: null,
      seasonCategory: null,
      quality: 'No_Quality',
      isFreemium: false,
      qualityOptions: getQualityOptions(),
      buscadoCat: false,
      isActive: 'RED',
      sponsor: false,
      userData: getUserData(),
      categoriesActives: [
        { value: true, text: this.$t('activar') },
        { value: false, text: this.$t('slider.deactivate') },
      ],
      background: null,
      backgroundUrl: null,
      shortDescription: null,
      labelStatus: 'RED',
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      genre: null,
      categories: [],
      categoryTypes: configCategoryType(true, false, false, false),
      type: null,
      clasification: null,
      external: [],
      selectedTag: [],
      idTags: [],
      originalTags: [],
      selectedZones: [],
      initialZones: [],
      idZones: [],
      publishingDate: null,
      batchSize: 100,
      finishingDate: null,
      dataExternal: [],
      dataChapters: [],
      tableColumns: [
        { key: 'Contenido', label: this.$t('contents.content') },
        { key: 'Editar', label: this.$t('dataGeneric.edit') },
      ],
      columns: [
        { key: 'prev', label: this.$t('prevCha') },
        { key: 'Contenido', label: this.$t('contents.content') },
        { key: 'next', label: this.$t('nextChap') },
      ],
      chapters: [],
      platformMode: '',
      buscadoKeep: false,
      maxViews: 0,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
      uniqueIds: null,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.userData.groups.edges.length !== 0) {
      this.sponsor = isUserInGroup(this.userData, constants.sponsor)
    }

    this.hasSubgroups = this.userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = this.userData.profile.subgroup
    }

    this.getClientData()
  },
  methods: {
    getClientData() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 platformMode
                 isAds
                 isDownload
             }
         }
         `,
        })
        .then(res => {
          this.platformMode = res.data.data.client.platformMode
          this.showAds = res.data.data.client.isAds
          this.showDownload = res.data.data.client.isDownload
        })
        .catch(() => {
        })
    },
    okAsg() {
      const { headers } = this
      this.showKeep = true
      let query = 'mutation{'

      this.chapters.forEach((element, index, array) => {
        const prevChapter = index === 0 ? '' : array[index].prev.id
        const nextChapter = index === array.length - 1 ? '' : array[index].next.id

        query += `q${index + 1}: updateContents(id: "${element.id}", input: { previousChapter: "${prevChapter}", nextChapter: "${nextChapter}" }) {
            content {
                id
                name
            }
        } `
      })
      query += '}'
      axios
        .post('', {
          query,
        }, { headers })
        .then(res => {
          messageError(res, this).then(result => {
            if (!result) {
              showToast(
                this.$t('success'),
                1, this,
              )
            }
          }).catch(() => {

          })

          this.showKeep = false
        })
        .catch(error => {
          this.showKeep = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    asgKeepWatching() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if2'),
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            // this.$refs["my-modal"].show();
            this.okAsg()
          } else {
            this.showKeep = false
          }
        })
        .catch(() => { })
    },
    async keepWatching() {
      this.getInfo()

      this.showKeep = true
      this.buscadoKeep = true
      this.buscadoCat = false
      this.buscado = false
      this.uniqueIds = null
      this.contents = []
      this.external = []
      this.dataExternal = []
      this.chapters = []
      this.dataChapters = []
      await this.getRelations(this.code)
    },
    asgImg(categories = false) {
      if (categories) this.uploadImg(this.categories, 2)
      else {
        if (this.contents.length > 0) this.uploadImg(this.contents, 1)
        if (this.external.length > 0) this.uploadImg(this.external, 0)
      }
    },
    async asgClas(categories = false) {
      let text = ''
      if (categories) {
        text = this.$t('automation.if23', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if5', { tags: this.selectedTag.join(', ') })
      }

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.show = true
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                      {
                        clasification: "${this.clasification.id}"
                      }){
                      ${categories ? 'category' : 'content'}{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                data.append('query', query)
                const { headers } = this
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(err => { console.log(err) })
    },
    async asgGen(categories = false) {
      const { headers } = this
      let text = ''
      if (categories) {
        text = this.$t('automation.if24', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if1', { tags: this.selectedTag.join(', ') })
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'

                batch.forEach((id, index) => {
                  query += `
              m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                {
                  genre: "${this.genre.id}"
                }){
                ${categories ? 'category' : 'content'}{
                  id
                  name

                }
              }
          `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgMaxViews() {
      const { headers } = this
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if6'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }
            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
              m${index + 1}: updateContents(id: "${id}", input:
                {
                  maxViews: ${this.maxViews}
                }){
                content{
                  id
                  name

                }
              }
          `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgShortDes(categories = false) {
      const { headers } = this
      let text = ''
      if (categories) {
        text = this.$t('automation.if25', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if7', { tags: this.selectedTag.join(', ') })
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }
            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()

                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
  m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
    {
      shortDescription: "${this.shortDescription}"
    }){
    ${categories ? 'category' : 'content'}{
      id
      name

    }
  }
`
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgDownload() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if8'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: updateContents(id: "${id}", input:
                      {
                        isDownload: ${this.isDownload}
                      }){
                      content{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgCheckContents() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if19'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: updateContents(id: "${id}", input:
                      {
                        showContentsCategories: ${this.showContentsCategories}
                      }){
                      content{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgFreemium() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if18'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: updateContents(id: "${id}", input:
                        {
                          isFreemium: ${this.isFreemium}
                        }){
                        content{
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgSub(categories = false) {
      const { headers } = this
      let text = ''
      if (categories) {
        text = this.$t('automation.if26', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if17', { tags: this.selectedTag.join(', ') })
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                      {
                        hasSubtitle: ${this.hasSubtitle}
                      }){
                      ${categories ? 'category' : 'content'}{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgAds() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if9'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: updateContents(id: "${id}", input:
                        {
                          isAds: ${this.isAds}
                        }){
                        content{
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selectedTag.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            allTags(name:"${this.selectedTag[this.selectedTag.length - 1]}") {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selectedTag[this.selectedTag.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
              mutation{
                createTag(input:{name:"${this.selectedTag[this.selectedTag.length - 1]}"}){
                    tag{
                        id
                        name
                    }
                }
            }
            `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selectedTag.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    async asgMainCategory() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if10', { maincategory: this.maincategory }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: updateContents(id: "${id}", input:
                        {
                          mainCategory: "${this.response.id}"
                        }){
                        content{
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgSeasonCategory() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if11', { seasonCategory: this.seasonCategory }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: updateContents(id: "${id}", input:
                        {
                          seasonCategory: "${this.response.id}"
                        }){
                        content{
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgTags(categories = false) {
      let text = ''
      if (categories) {
        text = this.$t('automation.if21', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if20', { tags: this.selectedTag.join(', ') })
      }

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                        {
                          tags: [${this.idTags}]
                        }){
                          ${categories ? 'category' : 'content'}
                        {
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                data.append('query', query)
                const { headers } = this
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgQuality() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if15', { quality: this.qualityOptions[this.quality] }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation($quality: ContentsContentQualityChoices) {'
                batch.forEach((id, index) => {
                  query += `
                      m${index + 1}: updateContents(id: "${id}", input:
                        {
                          quality: $quality
                        }){
                        content{
                          id
                          name

                        }
                      }
                  `
                })
                query += '}'
                const variables = {
                  quality: this.quality !== 'No_Quality' ? this.quality : null,
                }
                data.append('variables', JSON.stringify(variables))
                data.append('query', query)
                const { headers } = this
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },

    async asgType() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if12'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: updateContents(id: "${id}", input:
                      {
                        type: ${this.type}
                      }){
                      content{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    asgInteractivity(selectedInteractivity) {
      if (this.contentData.length === 0) {
        this.$swal({
          title: this.$t('error'),
          icon: 'error',
          text: this.$t('interactivities.noContentFound'),
          confirmButtonText: this.$t('dataGeneric.close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if20'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const noResourcesContent = []
            const data = new FormData()
            let query = 'mutation($bodyMail: String, $messagePush: String, $subjectMail: String, $subgroup: ID, $image: Upload, $fileMail: Upload) {'
            for (let i = 0; i < this.contentData.length; i += 1) {
              if (this.contentData[i].contentResources.edges.length === 0) {
                noResourcesContent.push(this.contentData[i].name)
              } else {
                query += `
                m${i + 1}: createInteractivities(input:
                  {
                      client:"${this.userData.profile.client.id}",
                      content:"${this.contentData[i].id}",
                      isActive: ${selectedInteractivity.isActive || false},
                      isVisible: ${selectedInteractivity.isVisible || false},
                      connect: ${selectedInteractivity.connect},
                      hasLottie: ${selectedInteractivity.hasLottie || false},
                      ${selectedInteractivity.hasLottie ? `lottieDesign: "${selectedInteractivity.lottieDesign}",` : ''}
                      ${selectedInteractivity.resourceStart.id !== null ? `resourceStart: "${this.contentData[i].contentResources.edges[0].node.resource.id}",` : ''}
                      ${selectedInteractivity.second !== null ? `second: ${selectedInteractivity.second},` : ''}
                      timeType: ${selectedInteractivity.timeType},
                      ${selectedInteractivity.date !== null ? `date:"${selectedInteractivity.date}",` : ''}
                      ${selectedInteractivity.hour !== null ? `hour: "${selectedInteractivity.hour}",` : ''}
                      ${selectedInteractivity.resourceEnd !== null ? `resourceEnd: "${selectedInteractivity.resourceEnd.id}",` : ''}
                      ${selectedInteractivity.secondFile !== null ? `secondFile: ${selectedInteractivity.secondFile},` : ''}
                      ${selectedInteractivity.time !== null ? `time: ${selectedInteractivity.time},` : ''}
                      typePush: ${selectedInteractivity.typePush},
                      isAuto: ${selectedInteractivity.isAuto || false},
                      ${selectedInteractivity.urlPush !== null ? `urlPush:"${selectedInteractivity.urlPush}",` : ''}
                      name: "${selectedInteractivity.name}",
                      messagePush: $messagePush,
                      subjectMail: $subjectMail,
                      description: "${selectedInteractivity.description}",
                      bodyMail: $bodyMail,
                      subgroup: $subgroup,
                      image: $image,
                      fileMail: $fileMail,
                  }){
                  interactivity{
                    name
                    client{
                      name
                    }
                    content{
                      name
                    }
                    description
                    isActive
                    isVisible
                    time
                    image
                    fileMail
                    resourceStart{
                      name
                    }
                    resourceEnd{
                      name
                    }
                    subgroup{
                      id
                      name
                    }
                  }
                }
               `
              }
            }
            query += '}'
            const variables = {
              bodyMail: selectedInteractivity.bodyMail != null ? selectedInteractivity.bodyMail : '',
              messagePush: selectedInteractivity.messagePush != null ? selectedInteractivity.messagePush : '',
              subjectMail: selectedInteractivity.subjectMail != null ? selectedInteractivity.subjectMail : '',
              subgroup: selectedInteractivity.subgroup?.id,
              image: selectedInteractivity.image !== '' ? selectedInteractivity.image.replace(/^.*(?=images_interactivity\/)/, '') : '',
              fileMail: selectedInteractivity.fileMail !== '' ? selectedInteractivity.fileMail.replace(/^.*(?=attach_email\/)/, '') : '',
            }

            data.append('variables', JSON.stringify(variables))
            data.append('query', query)
            const { headers } = this
            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                  if (noResourcesContent.length > 0) {
                    showToast(
                      `${this.$t('interactivities.noResourcesContent')}: ${noResourcesContent.join(', ')}`,
                      0, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                if (this.contentData.length === noResourcesContent.length) {
                  showToast(this.$t('interactivities.noResourcesFound'), 2, this)
                }
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    asgZones(categories = false) {
      let text = ''
      if (categories) {
        text = this.$t('automation.if28', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if27', { tags: this.selectedTag.join(', ') })
      }

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            await this.deleteCurrentCountryGroups(categories, totalArray)

            let query = 'mutation {'

            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: ${categories ? 'batchCreateCategoryCountryGroup' : 'batchCreateContentCountryGroup'}(input:[`
              let inputs = ''
              this.selectedZones.forEach(element => {
                inputs += `{
                  ${categories ? 'category' : 'content'}: ${totalArray[i]}
                  group: ${element.key}
                  publishDate: "${element.meta.publishDate}"
                  expirationDate: "${element.meta.expirationDate}"
                },`
              })
              inputs = inputs.slice(0, -1)
              query += inputs
              query += `]) {
                ${categories ? 'categoryCountryGroups' : 'contentCountryGroups'}
                {
                  id
                }
              }
          `
            }

            query += '}'
            data.append('query', query)
            const { headers } = this

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    getInfo() {
      this.background = this.response.background
      this.backgroundUrl = this.response.backgroundUrl
      this.genre = this.response.genre
      this.type = this.response.type
      this.clasification = this.response.clasification
      this.shortDescription = this.response.shortDescription
      this.maincategory = this.response.name
      this.seasonCategory = this.response.name
      this.quality = this.response.quality !== null ? this.response.quality : 'No_Quality'
      this.subgroup = this.response.subgroup
      this.response.tags.edges.forEach(element => {
        this.selectedTag.push(element.node.name)
        this.idTags.push(element.node.id)
        this.originalTags.push(element.node.name)
      })
      this.initialZones = this.response.categorycountrygroupSet.edges.map(element => ({
        key: element.node.group.id,
        label: element.node.group.name,
        meta: {
          publishDate: element.node.publishDate,
          expirationDate: element.node.expirationDate,
        },
      }))
      this.selectedZones = this.initialZones
    },
    async deleteCurrentCountryGroups(categories = false, totalArray) {
      for (let i = 0; i < totalArray.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await axios
          .post('', {
            query: `
            query {
              ${categories ? 'allCategories' : 'allContents'}(id:"${totalArray[i]}") {
                edges {
                  node {   
                  ${categories ? 'categorycountrygroupSet' : 'contentcountrygroupSet'}{                               
                      edges {
                        node {
                          id
                          publishDate
                          expirationDate
                          group {
                          id
                          name
                          }
                        }
                      }
                    }
                  }   
                }
              }
            }
          `,
          })
          .then(async res => {
            messageError(res, this)

            const foundCountryGroups = categories ? res.data.data.allCategories.edges[0] : res.data.data.allContents.edges[0]
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            let query = 'mutation {'
            if (foundCountryGroups) {
              const edges = categories
                ? foundCountryGroups.node.categorycountrygroupSet.edges
                : foundCountryGroups.node.contentcountrygroupSet.edges
              query += `${categories ? 'batchDeleteCategoryCountryGroup' : 'batchDeleteContentCountryGroup'}(ids:[`
              let ids = ''
              edges.forEach(element => {
                ids += `${element.node.id},`
              })
              ids = ids.slice(0, -1)
              query += ids
              query += `]) {
                  deletionCount
                }
              }
              `
              data.append('query', query)
              const { headers } = this

              await axios
                .post('', data, { headers }, config)
                .then(response => {
                  messageError(response, this)
                }).catch(() => {
                  showToast(this.$t('error'), 2, this)
                })
            }
          })
          .catch(() => {
            showToast(this.$t('error'), 2, this)
          })
      }
    },
    async clickElement() {
      this.getInfo()

      this.show = true
      this.buscado = true
      this.buscadoCat = false
      this.buscadoKeep = false
      this.uniqueIds = null
      this.contents = []
      this.external = []
      this.dataExternal = []
      this.chapters = []

      await this.getData(this.code)
    },
    exitFunct() {
      if (this.external.length > 0) {
        this.show = false

        this.$swal({
          title: this.$t('exter'),
          text: this.$t('existExter', { length: this.external.length }),
          icon: 'info',
          confirmButtonText: this.$t('code.accept'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
          .then(() => {

          })
          .catch(() => { })
      } else {
        this.show = false
      }
    },
    async getRelations(id, cursor = '') {
      const { headers } = this
      const idClient = b64ToUtf8(this.userData.profile.client.id).split(':')[1]

      await axios
        .post('', {
          query: `
            {
              contentByCategory(categoryId:"${id}",
              first:100, 
              after:"${cursor}",       
              client:"${idClient}",              
              excludedTypes:"FIL,EXA,TAS,ZOO,MEE") {
              totalCount
                pageInfo{
                    endCursor
                    hasNextPage
                  }
                edges {
                  node {
                    id
                    name
                    imageUrl
                    categories {
                      edges {
                        node {
                          id
                          name
                          order
                        }
                      }
                    }
                  }
                }
              }
            }
                `,
        }, { headers })
        .then(result => {
          messageError(result, this)
          const { edges, pageInfo: page } = result.data.data.contentByCategory
          // Inicializar un Set global para evitar duplicados
          if (!this.uniqueIds) {
            this.uniqueIds = new Set()
          }
          const newChapters = edges.filter(({ node }) => !this.uniqueIds.has(node.id))

          newChapters.forEach(({ node }) => this.uniqueIds.add(node.id))

          this.dataChapters.push(...newChapters)

          if (page.hasNextPage) {
            this.getRelations(id, page.endCursor)
          } else {
            const chaptersOriginal = this.dataChapters.map(({ node }) => node)
            // Agregar prev y next a cada objeto
            this.chapters = chaptersOriginal.map((item, index, array) => ({
              ...item,
              prev: index > 0 ? array[index - 1] : null,
              next: index < array.length - 1 ? array[index + 1] : null,
            }))
            this.showKeep = false
          }
        })
        .catch(err => { console.log(err) })
    },

    async getData(categoryId, cursor = '') {
      const { headers } = this
      const idClient = b64ToUtf8(this.userData.profile.client.id).split(':')[1]

      await axios.post('', {
        query: `{
            contentByCategory(
              categoryId: "${categoryId}"
              first: 100
              after:"${cursor}",       
              client:"${idClient}", 
            ) {
              totalCount
              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  name
                  imageUrl
                  type
                  mediaLocation
                  contentResources(isInitial:true){
                      edges{
                        node{
                          isInitial
                          content
                          resource{
                            id
                            name
                            type
                          }
                        }
                      }
                    }
                  categories {
                    edges {
                      node {
                        id
                        name
                        order
                      }
                    }
                  }
                }
              }
            }
          }`,
      }, { headers }).then(result => {
        messageError(result, this)

        const info = result.data.data.contentByCategory
        const conts = info.edges
        const page = info.pageInfo
        if (!this.uniqueIds) {
          this.uniqueIds = new Set()
        }
        //  Usar reduce para clasificar y almacenar los datos en una sola iteración
        const contentData = []

        const { contents, external, dataExternal } = conts.reduce(
          (acc, { node }) => {
            contentData.push(node)

            if (!this.uniqueIds.has(node.id)) {
              this.uniqueIds.add(node.id)
              if (node.mediaLocation === 'BUK') {
                acc.contents.push(node.id)
              } else {
                acc.external.push(node.id)
                acc.dataExternal.push(node)
              }
            }
            return acc
          },
          { contents: [], external: [], dataExternal: [] },
        )

        this.contents.push(...contents)
        this.external.push(...external)
        this.dataExternal.push(...dataExternal)
        this.contentData.push(...contentData.filter(n => this.uniqueIds.has(n.id)))

        if (page.hasNextPage) {
          this.getData(categoryId, page.endCursor)
        } else {
          this.exitFunct()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    errorImg(e) {
      e.target.src = fileError
    },
    clickCategory() {
      this.getInfo()

      this.show = true
      this.buscadoCat = true
      this.buscado = false
      this.external = []

      this.categories = []

      this.getDataCategory(this.code)
    },
    async asgActive() {
      const { headers } = this
      const isActiveMap = {
        GRE: this.$t('ecommerce.public'),
        RED: this.$t('code.desactivado'),
        default: this.$t('dataGeneric.internal'),
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if13', { isActive: isActiveMap[this.isActive] || isActiveMap.default }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let active = false
            switch (this.isActive) {
              case 'RED':
                active = false
                break
              default:
                active = true
                break
            }
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < this.categories.length; i += this.batchSize) {
              batches.push(this.categories.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'
                batch.forEach((id, index) => {
                  query += `
              m${index + 1}: updateCategory(id: "${id}", input:
                {
                  state: ${this.isActive},
                  isActive: ${active},
                }){
                category{
                  id
                  name

                }
              }
          `
                })
                query += '}'
                data.append('query', query)
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgQualityCategory() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if16', { quality: this.qualityOptions[this.quality] }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            const batches = []
            for (let i = 0; i < this.categories.length; i += this.batchSize) {
              batches.push(this.categories.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation($quality: CategoryQualityInput) {'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: updateCategory(id: "${id}", input:
                      {
                        quality: $quality
                      }){
                      category{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                const variables = {
                  quality: this.quality !== 'No_Quality' ? this.quality : null,
                }
                data.append('variables', JSON.stringify(variables))
                data.append('query', query)
                const { headers } = this
                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },

    async getDataCategory(categoryId, cursor = '') {
      const { headers } = this
      const idClient = b64ToUtf8(this.userData.profile.client.id).split(':')[1]

      axios.post('', {
        query: `{
        categoriesByCategory(
          categoryId: "${categoryId}"
          first: 100
          after: "${cursor}"
          client: "${idClient}"
        ) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              mediaLocation
              name
              imageUrl
            }
          }
        }
      }`,
      }, { headers }).then(result => {
        messageError(result, this)
        const { edges: categories, pageInfo: page } = result.data.data.categoriesByCategory

        // Agregar IDs directamente sin necesidad de una variable intermedia
        this.categories.push(...categories.map(({ node }) => node.id))
        if (page.hasNextPage) {
          this.getDataCategory(categoryId, page.endCursor)
        } else {
          this.show = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async asgStatus() {
      const { headers } = this

      const isActive = !!(this.labelStatus === 'GRE' || this.labelStatus === 'YEW')

      const dateIn = this.fechaFormato(this.publishingDate)
      const dateFin = this.fechaFormato(this.finishingDate)
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if14'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                let query = this.labelStatus === 'GRE'
                  ? 'mutation($publishDate: DateTime,$expirationDate: DateTime){'
                  : 'mutation{'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: updateContents(id: "${id}", input:
                      {
                        state: ${this.labelStatus},
                        isActive:${isActive},
                    ${this.labelStatus === 'GRE' ? 'publishDate: $publishDate, expirationDate: $expirationDate,' : ''}
                                    }){
                                    content{
                                      id
                                      name

                                    }
                                  }
                              `
                })
                query += '}'
                try {
                  const res = await axios.post('', {
                    variables: {
                      publishDate: dateIn,
                      expirationDate: dateFin,
                    },
                    query,
                  }, { headers })
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    async asgSubgroup(categories = false) {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: categories ? this.$t('automation.if-subgroup2') : this.$t('automation.if-subgroup1'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            let totalArray = []

            totalArray = categories ? [...this.categories] : [...this.contents, ...this.external]
            this.show = true

            const batches = []
            for (let i = 0; i < totalArray.length; i += this.batchSize) {
              batches.push(totalArray.slice(i, i + this.batchSize))
            }

            await Promise.all(
              batches.map(async batch => {
                let query = 'mutation($subgroup: ID){'
                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                      {
                        subgroup: $subgroup,
                      }){
                      ${categories ? 'category' : 'content'}{
                        id
                        name

                      }
                    }
                `
                })
                query += '}'
                try {
                  const res = await axios.post('', {
                    variables: {
                      subgroup: this.subgroup?.id,
                    },
                    query,
                  }, { headers })
                  const errorHandled = await messageError(res, this)
                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )
            this.show = false
          }
        })
        .catch(() => { })
    },
    estados() {
      if (this.labelStatus === 'GRE') {
        document.getElementById('fechas').style.display = 'flex'
      } else {
        document.getElementById('fechas').style.display = 'none'
      }
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
    async uploadImg(destinationArray, sourceType) {
      const uploadDestination = {
        EXTERNAL_CONTENT: 0,
        INTERNAL_CONTENT: 1,
        CATEGORY: 2,
      }
      const { headers } = this
      let text = ''
      if (sourceType === uploadDestination.CATEGORY) {
        text = this.$t('automation.if22', { tags: this.selectedTag.join(', ') })
      } else if (sourceType === uploadDestination.INTERNAL_CONTENT) {
        text = this.$t('automation.if3', { tags: this.selectedTag.join(', ') })
      } else if (sourceType === uploadDestination.EXTERNAL_CONTENT) {
        text = this.$t('automation.if4', { tags: this.selectedTag.join(', ') })
      }

      this.$swal({
        title: sourceType !== uploadDestination.EXTERNAL_CONTENT ? this.$t('warning') : this.$t('IntroUrl'),
        text,
        icon: sourceType !== uploadDestination.EXTERNAL_CONTENT ? 'error' : 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }

            // Dividir destinationArray en lotes de 10 elementos
            const batchSize = 10
            const batches = []
            for (let i = 0; i < destinationArray.length; i += batchSize) {
              batches.push(destinationArray.slice(i, i + batchSize))
            }

            // Ejecutar todas las peticiones en paralelo por lotes
            await Promise.all(
              batches.map(async batch => {
                const data = new FormData()
                let query = 'mutation {'

                batch.forEach((id, index) => {
                  query += `
                    m${index + 1}: ${sourceType === uploadDestination.CATEGORY ? 'updateCategory' : 'updateContents'}(id: "${id}", input:
                      {
                        mediaLocation: ${sourceType !== uploadDestination.EXTERNAL_CONTENT ? 'BUK' : 'DIR'}
                        backgroundUrl: "${sourceType === uploadDestination.EXTERNAL_CONTENT ? this.backgroundUrl : ''}"
                      }){
                      ${sourceType === uploadDestination.CATEGORY ? 'category' : 'content'}{
                        id
                        name
                      }
                    }
                  `
                })

                query += '}'
                data.append('query', query)

                if (sourceType !== uploadDestination.EXTERNAL_CONTENT) {
                  let backgroundFile = null
                  if (this.backgroundUrl) {
                    try {
                      const response = await fetch(this.backgroundUrl)
                      if (!response.ok) throw new Error('Error al subir imagen')

                      const blob = await response.blob()
                      backgroundFile = new File([blob], 'background_file', { type: blob.type })
                    } catch (err) {
                      console.error('Error al obtener la imagen:', err)
                    }
                  }

                  if (backgroundFile) {
                    data.append('background', backgroundFile)
                  }
                }

                try {
                  const res = await axios.post('', data, { headers }, config)
                  const errorHandled = await messageError(res, this)

                  if (!errorHandled) {
                    showToast(
                      this.$t('actContent', { batch: batch.length }),
                      1,
                      this,
                    )
                  }
                } catch (error) {
                  console.log(error)
                  showToast(this.$t('error'), 2, this)
                }
              }),
            )

            this.show = false
          }
        })
        .catch(err => { console.log(err) })
    },
    showModal() {
      this.$refs.IntModal.show()
    },
    hideModal() {
      this.$refs.IntModal.hide()
    },
    InteractivitySelection(data) {
      this.asgInteractivity(data)
      this.hideModal()
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#automation .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

#automation .subgroup {
  min-width: -webkit-fill-available !important;
}

#automation .vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}

#automation .modal {
  overflow-y: auto;
}

#automation .row {
  justify-content: space-around;
}

/*
Full screen Modal
*/
#automation .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
</style>
